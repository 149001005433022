import { IAddUpdateChallan } from "src/app/core/interfaces/order/order-management/challan/add-update-challan";

export class AddUpdateChallanModel implements IAddUpdateChallan{
    challanId: number;
    customerId: number;
    shopId: number;
    addressId: number;
    actionbyLoginUserId: number;
    actionbyUserTypeEnumId: number;
    statusEnumId: number;
    challanType: number;
    netAmount:number
    grossAmount:number
    totalTaxAmount:number
    totalDiscount:number
    totalTaxable:number
    roundOff:number
    total: number
    challandetail: [
        { challan_detail_id: number;
          product_id: number;
          product_quantity: number;
          mrp:number,
          unit_price: number;
        }
            ];

}