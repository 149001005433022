import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from 'src/app/core/interfaces/common/common-response';
import { IGetState } from 'src/app/core/interfaces/common/state-city/get-state';
import { IGetCity } from 'src/app/core/interfaces/common/state-city/get-city';
import { IShopMasterModel } from 'src/app/core/models/masters/shop/shop-master-model';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetMainShopList } from 'src/app/core/interfaces/masters/shop/get-mainShop-list';



@Injectable({
    providedIn: 'root'
  })
  export class ShopService {
  
    constructor(private http: HttpClient) { }
    serverEndPoint = environment.apiUrl;

   getStateList(id: Number): Observable<IGetState> {
        const url = this.serverEndPoint + 'getStates?country_id=' + id;
        return this.http.get<IGetState>(url);
      }
    
      getCityList(id: Number): Observable<IGetCity> {
        const url = this.serverEndPoint + 'getCities?state_id=' + id;
        return this.http.get<IGetCity>(url);
      }

     addUpdateShop(shopMasterModel: IShopMasterModel ): Observable<ICommonResponse> {
      const url = this.serverEndPoint + 'addUpdateSubShop';
      console.log(url,',odel',shopMasterModel)
       return this.http.post<ICommonResponse>(url,shopMasterModel)
     }

     getShopTableData(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
        const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
        return this.http.get<IGetShopTable>(url);
      }

      getShopMainShopList(mainshopId: Number,statusEnumId:Number): Observable<IGetMainShopList> {
        const url = this.serverEndPoint + 'getMainShopList?mainShopId='+ mainshopId +  '&statusEnumId=' + statusEnumId;
        return this.http.get<IGetMainShopList>(url);
      }
     
  
  
  }