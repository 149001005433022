import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetActiveCategory, } from '../../../interfaces/inventory/category/active-category-data';
import { IGetActiveType } from '../../../interfaces/inventory/type/active-type-data';
import { IGetBrandCard } from '../../../interfaces/inventory/brand/brand-card';
import { ProductGenInfoMaster } from 'src/app/core/models/inventory/product/product-geninfo-master-model';
import { IGetActiveTax } from '../../../interfaces/inventory/tax/active-tax-data';
import { IGetColoursData } from 'src/app/core/interfaces/inventory/product/colours-data';
import { IGetTax } from '../../../interfaces/inventory/tax/tax';
import { IGetBrandGrade } from '../../../interfaces/inventory/brand/brand-grade';
import { IGetProduct } from 'src/app/core/interfaces/inventory/product/product';
import { IGetUnit } from 'src/app/core/interfaces/inventory/product/unit';
import { ProductSpecification } from 'src/app/core/models/inventory/product/product-specification-master-model';
import { ProductDetails } from 'src/app/core/models/inventory/product/product-details-master-model';
import { ProductPackaging } from 'src/app/core/models/inventory/product/product-packaging-master-model';
import { IGetProductInfo } from 'src/app/core/interfaces/inventory/product/get-productinfo';
import { IGetSpecificationDetails } from 'src/app/core/interfaces/inventory/product/get-specification-details';
import { IGetPackaging } from 'src/app/core/interfaces/inventory/product/get-packaging';
import { IGetProductDetails } from 'src/app/core/interfaces/inventory/product/get-product-details';
import { IGetProductRemark } from 'src/app/core/interfaces/inventory/product/product-remark';
import { IGetBodyColorTable } from 'src/app/core/interfaces/masters/body-color/get-bodyColor-table';
import { IGetLightColorTable } from 'src/app/core/interfaces/masters/light-color/get-lightColor-table';
import { IGetFamilyType } from 'src/app/core/interfaces/masters/family-type/get-family-type';
import { PurchaseSalesModel } from 'src/app/core/models/inventory/product/purchase-sales-master-model';
import {BulkUploadModel } from 'src/app/core/models/inventory/product/bulk-upload-model'

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getCategoryList(id: Number): Observable<IGetActiveCategory> {
    const url = this.serverEndPoint + 'getCategoryByLoginUserId?actionLoginUserId=' + id;
    return this.http.get<IGetActiveCategory>(url);
  }

  getTypeList(userId: Number, categoryId: Number): Observable<IGetActiveType> {
    const url = this.serverEndPoint + 'getTypeListByLoginUserId?actionByLoginUserId=' + userId + '&categoryId=' + categoryId;
    return this.http.get<IGetActiveType>(url);
  }

  getBrandCardList(): Observable<IGetBrandCard> {
    const url = this.serverEndPoint + 'getBrandForCard';
    return this.http.get<IGetBrandCard>(url);
  }

  addProductGeneralInfo(productGenInfoModel: ProductGenInfoMaster): Observable<any> {
    const url = this.serverEndPoint + 'insertProductGeneralInfo';
    console.log(url, ',odel', productGenInfoModel)
    return this.http.post<ICommonResponse>(url, productGenInfoModel)
  }


  getTaxList(): Observable<IGetActiveTax> {
    const url = this.serverEndPoint + 'getTaxList';
    return this.http.get<IGetActiveTax>(url);
  }

  
  getbodyColorData(id: Number, statusEnumId:Number): Observable<IGetBodyColorTable> {
    const url = this.serverEndPoint + 'getBodyColor?bodyColorId='+ id +'&statusEnumId=' + statusEnumId;
    return this.http.get<IGetBodyColorTable>(url);
  }

  getLightColorData(id: Number, statusEnumId:Number): Observable<IGetLightColorTable> {
    const url = this.serverEndPoint + 'getLightColor?lightColorId='+ id +'&statusEnumId=' + statusEnumId;
    return this.http.get<IGetLightColorTable>(url);
  }

  getTaxDetail(id: Number): Observable<IGetTax> {
    const url = this.serverEndPoint + 'getTaxDetail?taxId=' + id;
    return this.http.get<IGetTax>(url);
  }

  getBrandGradeList(id: any): Observable<IGetBrandGrade> {
    const url = this.serverEndPoint + 'getGrade?brandId=' + id;
    return this.http.get<IGetBrandGrade>(url);
  }

  getProductList(loginUserId: number, publishedStatusEnumId: number, statusEnumId: number): Observable<IGetProduct> {
    const url = this.serverEndPoint + 'GetProductForTable?LoginUserId=' + loginUserId + '&publishedStatusEnumId=' + publishedStatusEnumId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetProduct>(url);
  }

  getUnit(id: any): Observable<IGetUnit> {
    const url = this.serverEndPoint + 'getUnit?unitGroupId=' + id;
    return this.http.get<IGetUnit>(url);
  }

  addProductDetails(productdetailsModel: ProductDetails): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addProductDetails';
    console.log(url, ',odel', productdetailsModel)
    return this.http.post<ICommonResponse>(url, productdetailsModel)
  }

  addProductSpecification(productSpecificationModel: ProductSpecification): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'insertProductSpecification';
    console.log(url, ',odel', productSpecificationModel)
    return this.http.post<ICommonResponse>(url, productSpecificationModel)
  }

  addProductPackaging(productPackagingModel: ProductPackaging): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'insertProductPackagingDetails';
    console.log(url, ',odel', productPackagingModel)
    return this.http.post<ICommonResponse>(url, productPackagingModel)
  }

  getProductInfo(id: number): Observable<IGetProductInfo> {
    const url = this.serverEndPoint + 'getProductInfo?productId=' + id;
    return this.http.get<IGetProductInfo>(url);
  }

  getSpecificationDetails(id: number): Observable<IGetSpecificationDetails> {
    const url = this.serverEndPoint + 'GetSpecificationDetail?productId=' + id;
    return this.http.get<IGetSpecificationDetails>(url);
  }

  getPacakigingData(id: number): Observable<IGetPackaging> {
    const url = this.serverEndPoint + 'getProductPackaging?productId=' + id;
    return this.http.get<IGetPackaging>(url);
  }

   getProductDetails(id:number):Observable<IGetProductDetails>{
     const url = this.serverEndPoint + 'GetProductDetails?productId=' + id;
     return this.http.get<IGetProductDetails>(url)
   }

   getProductRemarkHistory(id:number):Observable<IGetProductRemark>{
    const url = this.serverEndPoint + 'getProductRemarkHistory?productId=' + id;
    return this.http.get<IGetProductRemark>(url)
  }

  getFamilyTypeData(id: Number): Observable<IGetFamilyType> {
    const url = this.serverEndPoint + 'getFamilyDetail?familyId='+ id ;
    return this.http.get<IGetFamilyType>(url);
  }

  insertProductPurchaseAndSales(putchaseAndSalesModel: PurchaseSalesModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'insertProductPurchaseAndSales';
    console.log(url, ',odel', putchaseAndSalesModel)
    return this.http.post<ICommonResponse>(url, putchaseAndSalesModel)
  }

  getPurchaseSalesDetail(productId:number,statusEnumId:number):Observable<IGetProductRemark>{
    const url = this.serverEndPoint + 'getProductPurchsaseAndSalesDetails?productId='+ productId+'&statusEnumId='+statusEnumId
   return this.http.get<IGetProductRemark>(url)

  }

  getPurchaseSalesDetails(productId:number):Observable<IGetProductRemark>{
    const url = this.serverEndPoint + 'getProductPurchsaseAndSalesDetail?productId='+ productId
   return this.http.get<IGetProductRemark>(url)

  }

  bulkUploadProduct(BulkUploadModel: BulkUploadModel): Observable<any> {
    const url = this.serverEndPoint + 'uploadBulkProduct';
    console.log(url, ',odel', BulkUploadModel)
    return this.http.post<any>(url, BulkUploadModel)
  }
  // https://jblights.kritin.in/api/getProductDetailsForTable?LoginUserId=0&publishedStatusEnumId=26&statusEnumId=0&limit=100&page=1
  getProductListWithLimit(loginUserId: number, publishedStatusEnumId: number, statusEnumId: number, limit:number, page:number): Observable<any> {
    const url = this.serverEndPoint + 'getProductDetailsForTable?LoginUserId=' + loginUserId + '&publishedStatusEnumId=' + publishedStatusEnumId + '&statusEnumId=' + statusEnumId+'&limit='+limit+'&page='+page;
    return this.http.get<any>(url);
  }
}