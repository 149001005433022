import { IChallanToInvoice } from "src/app/core/interfaces/order/order-management/challan/challan-to-invoice";


export class ModelChallanToInvoice implements IChallanToInvoice{
    orderId: number;
    customerId: number;
    subTotal: number;
    cartId: number;
    discountAmount: number;
    orderDate: string;
    totalTax: number;
    taxAmount: number;
    otherCharges: number;
    couponCodeOrGiftCard: string;
    shopId: number;
    shopType: number;
    orderType: number;
    orderName: string;
    paymentType: string;
    deliveryAddressId: number;
    actionbyLoginUserId: number;
    actionbyUserTypeEnumId: number;
    statusEnumId: number;
    actionOnDate: string;
    challanId:number;
    netAmount:number
    grossAmount:number
    totalTaxAmount:number
    totalDiscount:number
    totalTaxable:number
    roundOff:number
    orderDetail:Array<{
        product_id: number;
        order_detail_id:  number; 
        mrp: number;
        no_of_product: number;  
        unit_price :number;
        "sales_price":number;
      "fp_scheme_order_detail":Array<
        {"scheme_order_id":number,"scheme_id":number,"scheme_amount":number}
      >
    }>
    
    // orderDetail: { product_id: number; order_detail_id: number; mrp: number; no_of_product: number; unit_price: number; }[];

}