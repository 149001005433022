import { IAddCustomerAddress } from "src/app/core/interfaces/order/order-management/sales-order/add-customer-address";

export class addCustomerAddressModel implements IAddCustomerAddress{
    addresId: number;
    stateId: number;
    cityId: number;
    pincode: number;
    address: string;
    mobileNumber: number;
    defaultAddress: boolean;
    customerId: number;
    statusEnumId: number;
    contactPersonName: string;
    actionByLoginUserId: number;
    actionByUserTypeEnumId: number;

}