
import { addPosPayLaterAndNow } from "src/app/core/interfaces/order/order-management/sales-order/add-pos-payLaterAndNow";

export class addPosPayLaterAndNowModel implements addPosPayLaterAndNow {
    paymentId: String;
    invoiceId: number;
    orderId: number;
    customerId: number;
    shopId:  number;
    paymentMode:  number;
    paymentType: string;
    subtotal:  number;
    depositAmount:any;
    totalTax:  number;
    otherCharges: number;
    depositOnDate: string;
    receivedBankId:  number;
    holderName: string;
    acountNo:  number;
    ifscCode: string;
    bankName: string;
    bankBranchName: string;
    chequeType: string;
    chequePayName: string;
    chequeNoOrUpiId: string;
    statusEnumId:  number;
    remark: string;
    actionbyLoginUserId:   number;
    actionbyUserTypeEnumId:  number;
    invoiceDetail: [
      {
        invoice_detail_id:  number;
        product_id:  number;
        dispatch_quantity: number;
        per_product_price: number;
        free_no_of_item:  number;
        tax_amount:  number;
        sale_discount:  number;
    // product_id: number;
        // order_detail_id:  number; 
        mrp: number;
        no_of_product: number;  
        unit_price :number;
        "sales_price":number;
      "fp_scheme_order_detail":[
        {"scheme_order_id":number,"scheme_id":number,"scheme_amount":number}
      ]
        
      }
    ]
    netAmount:number
    grossAmount:number
    totalTaxAmount:number
    totalDiscount:number
    totalTaxable:number
    roundOff:number
    // invoiceDetail:[{
    //     product_id: number;
    //     order_detail_id:  number; 
    //     mrp: number;
    //     no_of_product: number;  
    //     unit_price :number;
    //     "sales_price":number;
    //   "fp_scheme_order_detail":Array<
    //     {"scheme_order_id":number,"scheme_id":number,"scheme_amount":number}
      
    // }]
}