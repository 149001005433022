import { IInwardMaster, InwardDetail, poIdArrays } from "src/app/core/interfaces/purchase/inward/inward-master";
export class InwardMaster implements IInwardMaster{
    inwardId:number; 
    po_id: Number;
    // poId: Number;

    vendorId : number;
    inwaradDate : string; 
    invoiceNo : number;
    invoiceDate :string; 
    remark : string;
    statusEnumId:number;
    actionByLoginUserId:number;
    actionByUserTypeEnumId:number;
    shopId: number ;
    inwardDetail:Array<InwardDetail>;
    poId:Array<poIdArrays>

}