import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetInwardStockTransfer } from 'src/app/core/interfaces/order/order-management/inward/inward-stock-transfer/get-inward-stockTransnfer';
import { IGetProductSalesOrder } from 'src/app/core/interfaces/order/order-management/sales-order/get-product-sales-order';
import { IGetStockTransferShopWiseData } from 'src/app/core/interfaces/order/order-management/stock-transfer/get-stock-transfer-shop-wise-data';
import { IGetTransferShopDetailData } from 'src/app/core/interfaces/order/order-management/stock-transfer/get-transfer-shop-detail-data';
import { IGetTransferedStockDetail } from 'src/app/core/interfaces/order/order-management/stock-transfer/getTransferedStockDetail/get-transfered-stock-detail';
import { IListTransferStock } from 'src/app/core/interfaces/order/order-management/stock-transfer/list-transfer-stock/list-transfer-stock';
import { IGetInwardTable } from 'src/app/core/interfaces/purchase/inward/inward-table';
import { SaveInwardStockTransferModel } from 'src/app/core/models/order/order-management/sales-order/inward/save-Inward-Stock-Transfer-Model';
import { AddStockTransferModel } from 'src/app/core/models/order/order-management/sales-order/stock-transfer/add-stock-transfer-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockTransferService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;

  addStockTransfer(addStockTransferModel: AddStockTransferModel): Observable<any> {
    const url = this.serverEndPoint + 'addStockTransfer';
    return this.http.post<any>(url, addStockTransferModel)
  }

  getTransferShopDetail(shopId: number, statusEnumId:number):Observable<IGetTransferShopDetailData> {
      const url = this.serverEndPoint + 'gettransferShopDetail?transferFromShopId='+shopId+'&statusEnumId='+statusEnumId+'';
      return this.http.get<IGetTransferShopDetailData>(url)
    }

  getStockShopWiseForTransfer(shopId: number, statusEnumId:number):Observable<IGetStockTransferShopWiseData> {
    const url = this.serverEndPoint + 'getStockShopWiseForTransfer?shopId='+shopId+'&statusEnumId='+statusEnumId+'';
    return this.http.get<IGetStockTransferShopWiseData>(url)
  }

  getShopTableData(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }

  getProductListForStockTransfer(categoryId: number, typeId: number,brandId:number, shopId: Number):Observable<IGetProductSalesOrder> {
    const url = this.serverEndPoint + 'getProductForSalesOrderlist?categoryId='+categoryId+'&typeId='+typeId+'&brandId='+brandId+'&shopId='+shopId+'';
    return this.http.get<IGetProductSalesOrder>(url)
  }

  getTransferedStockDetailByStockTransferId(id: number): Observable<IGetTransferedStockDetail>{
    const url = this.serverEndPoint + 'getTransferedStockDetail?stockTransferId='+id+'';
    return this.http.get<IGetTransferedStockDetail>(url)
  }

  getlistofTransferedStock(id:number): Observable<IListTransferStock>{
  
    const url = this.serverEndPoint + 'listofTransferedStocktable?stockTransferId='+id+'';
    return this.http.get<IListTransferStock>(url)
  }

  getlistofTransferedStocks(id:number): Observable<IListTransferStock>{
    //  https://admin.jaibharatindore.com/api/getInwardTransferStock?stockTransferId=0
      const url = this.serverEndPoint + 'getInwardTransferStock?stockTransferId=0';
      return this.http.get<IListTransferStock>(url)
    }
  saveInwardStockTransfer(SaveInwardModel:SaveInwardStockTransferModel): Observable<any>{
    const url = this.serverEndPoint + 'addInwardStockTransfer';
    return this.http.post<any>(url,SaveInwardModel )
  }
 
  getInwardTableData(id : number): Observable<IGetInwardTable> {
    const url = this.serverEndPoint + 'getInwardForTable?statusEnumId='+ id;
    return this.http.get<IGetInwardTable>(url);
  }

  getInwardStockTransferTable(shopId: Number):Observable<IGetInwardStockTransfer> {
    const url = this.serverEndPoint + 'getTransferStock?transferFromShopId='+ shopId + '&statusEnumId=1';
    return this.http.get<IGetInwardStockTransfer>(url)
  }
}
