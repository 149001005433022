import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICommonResponse } from "src/app/core/interfaces/common/common-response";
import { IBankDetail, IBrandAssociation, ICustomerAssociation, IShopAccess, IShopAssociation } from "src/app/core/interfaces/user/employee/bankDetail";
import { documentDetailList, IDocumentData } from "src/app/core/interfaces/user/employee/document";
import { IPersonalInfo, IpersonalInfoResponse } from "src/app/core/interfaces/user/employee/personalData";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class EmployeeService {
  
    constructor(private http: HttpClient) { }
    serverEndPoint = environment.apiUrl;

    savePersonalInformation(model: IPersonalInfo):Observable<IpersonalInfoResponse> {
      const url = this.serverEndPoint + 'addUpdateEmployeeInfor';
      return this.http.post<IpersonalInfoResponse>(url, model)
    }

    //https://jewelpartner-admin.kritin.tech/api/getAllDocumentEmployeeWise?applicableForEnum_id=34&employee_id=4
    getDocumentList(id:number):Observable<any> {
      const url = this.serverEndPoint + 'getAllDocumentEmployeeWise?applicableForEnum_id=31&employee_id='+id;  // 34 --> 31
      return this.http.get<any>(url);
    }

    getEmployeeDetail(id:number):Observable<any> {
      const url = this.serverEndPoint + 'getEmployeeDetail?employee_id='+id;
      return this.http.get<any>(url);
    }

    addUpdateDocument(model: IDocumentData): Observable<ICommonResponse> {
      const url = this.serverEndPoint + 'insertEmployeeDocumentsDetail';
      return this.http.post<ICommonResponse>(url, model)
    }

    addUpdateBankDetail(model:IBankDetail) {
      const url = this.serverEndPoint + 'addUpdateEmployeeBankDetails';
      return this.http.post<ICommonResponse>(url,model);
    }

    getBankDetail(id:number):Observable<any> {
      const url = this.serverEndPoint + 'getEmployeeBankDetails?employeeId='+id;
      return this.http.get<any>(url);
    }

    getDesignationList() {
      const url = this.serverEndPoint + 'getDesignations?designationId=0';
      return this.http.get<any>(url);
  }

  addBrandAssociatedWithEmployee(model:IBrandAssociation) {
    const url = this.serverEndPoint + 'AddBrandAssociatedWithEmployee';
    return this.http.post<ICommonResponse>(url,model);
  }
  addUserAssociatedWithEmployee(model:ICustomerAssociation) {
    const url = this.serverEndPoint + 'AddUserAssociatedWithEmployee';
    return this.http.post<ICommonResponse>(url,model);
  }
  AddShopAssociatedWithEmployee(model:IShopAssociation) {
    const url = this.serverEndPoint + 'AddShopAssociatedWithEmployee';
    return this.http.post<ICommonResponse>(url,model);
  }

      getBrandAssociatedWithEmployee(id:number) {
        const url = this.serverEndPoint + 'getBrandAssociatedWithEmployee?employeeId='+id;
        return this.http.get<any>(url);
    }
    getUserAssociatedWithEmployee(id:number) {
      const url = this.serverEndPoint + 'getUserAssociatedWithEmployee?employeeId='+id;
      return this.http.get<any>(url);
    } 
    getShopAssociatedWithEmployee(id:number) {
      const url = this.serverEndPoint + 'getShopAssociatedWithEmployee?employeeId='+id;
      return this.http.get<any>(url);
    }
    getShopRoleList() {
      const url = this.serverEndPoint + 'getShopRoleList';
      return this.http.get<any>(url);
    }
    getShopAccessList() {
      const url = this.serverEndPoint + 'getShopAccessList';
      return this.http.get<any>(url);
    }
    getShopAccessListByRoleId(roleId:Number) {
      const url = this.serverEndPoint + 'getShopAccessPageAssociatedWithShopRole?shopRoleId='+roleId+'&statusEnumId=1';
      return this.http.get<any>(url);
    }
    // getShopAccessPageAssociatedWithShopRole?shopRoleId=1&statusEnumId=1
    

    addShopRoleAssociateWithAccessPage(model:IShopAccess) {
      const url = this.serverEndPoint + 'addShopRoleAssociateWithAccessPage';
      return this.http.post<ICommonResponse>(url,model);
    }
  
  // http://localhost:7000/api/getBrandAssociatedWithEmployee?employeeId=30
  //  http://localhost:7000/api/getUserAssociatedWithEmployee?employeeId=30
  //  http://localhost:7000/api/getShopAssociatedWithEmployee?employeeId=30
}